<div class="confirm-dialog">
  <p mat-dialog-title>
    {{ message }}
 </p>
  <div mat-dialog-content>
    <span class="body-content">
      {{ body }}
    </span>
    <div class="buttons-div">
      <button class="close-button" mat-raised-button (click)="onCancel()">
      Cancelar
    </button>
      <button class="save-button" mat-raised-button color="accent" (click)="onConfirm()">
        Aceptar
      </button>
    </div>
  </div>
</div>